import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "list"]

  connect() {
    // Listen for clicks on suggestion items.
    this.listTarget.addEventListener("click", (event) => {
      if (event.target && event.target.matches("li")) {
        this.selectSuggestion(event.target.dataset.value);
      }
    });
    // Hide suggestions when clicking outside.
    document.addEventListener("click", (event) => {
      if (!this.element.contains(event.target)) {
        this.hideSuggestions();
      }
    });
  }

  updateSuggestions() {
    const query = this.inputTarget.value.toLowerCase();
    const suggestions = this.listTarget.querySelectorAll("li");
    let hasVisible = false;
    suggestions.forEach((li) => {
      if (li.dataset.value.toLowerCase().includes(query)) {
        li.style.display = "block";
        hasVisible = true;
      } else {
        li.style.display = "none";
      }
    });
    this.listTarget.style.display = hasVisible ? "block" : "none";
  }

  selectSuggestion(value) {
    this.inputTarget.value = value;
    this.hideSuggestions();
  }

  hideSuggestions() {
    this.listTarget.style.display = "none";
  }
}
