import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]

  connect() {
    this.activeTab = this.tabTargets.find(t => t.href === window.location.href)
    this.activeTab?.classList.add("active")
  }

  select(event) {
    event.preventDefault()
    this.tabTargets.forEach(t => t.classList.remove("active"))
    event.currentTarget.classList.add("active")
  }
}
